import React, {useState} from "react";
import ContentSection from "../components/ContentSection";
import lsk from '../assets/landschildi-u.webp';
import wsk from '../assets/photo_2024-07-26_16-08-29.webp';
import Button from "../components/Button";
import {Helmet} from "react-helmet";

// Accordion components
const AccordionItem = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={toggleAccordion}>
                <h2>{title}</h2>
                <p>{isOpen ? "-" : "+"}</p>
            </div>
            {isOpen && (
                <div className="accordion-content">
                    {children}
                </div>
            )}
        </div>
    );
};

const Accordion = ({items}) => {
    return (
        <div className="accordion">
            {items.map((item, index) => (
                <AccordionItem key={index} title={item.title}>
                    {item.content}
                </AccordionItem>
            ))}
        </div>
    );
};

// Map section
const MapSection = () => {
    return (
        <section className="map-section content-padding intro-help-container">
            <h2>Tierheime und Auffangstationen</h2>
            <div className="map-container">
                <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/d/embed?mid=1YCGzfd02ZPIL3VdPXUi6Coj4l76SPMza&ehbc=2E312F"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{border: 0}}
                    allowFullScreen
                ></iframe>
            </div>
        </section>
    );
};

// Divider section
const Divider = () => {
    return <section className="divider-section"></section>;
};

// Hilfe component with anchor links and accordion
const Hilfe = () => {
    const accordionItems = [
        {
            title: "Vorgehen bei Fundtieren in Deutschland",
            content: (
                <div>
                    <p>Der Fund einer Schildkröte sollte in Deutschland zuerst dem Fundbüro (beim Ordnungsamt / im
                        Rathaus oder Bezirksamt) gemeldet werden, dann bei der Citesbehörde (Artenschutzbehörde im
                        Umweltamt, meist im Regierungspräsidium). Außerdem sollte das Tierheim oder die nächstgelegene
                        Auffangstation benachrichtigt werden.</p>
                    <p>Die Auffangstationen und Reptilientierärzte findet man in der Google-Map oben. Wird ein Fundtier
                        nicht ordnungsgemäß gemeldet, riskiert man Anzeigen wegen Fundunterschlagung und Verstoß gegen
                        das Artenschutzgesetz.</p>
                </div>
            )
        },
        {
            title: "Vorgehen bei Fundtieren in Österreich",
            content: (
                <p>Der Fund einer Schildkröte in Österreich sollte zuerst der Polizei, dann dem nahegelegenen Tierheim
                    und dann der zuständigen Bezirkshauptmannschaft, oder dem Magistrat gemeldet werden. Fundämter sind
                    in Österreich nicht zuständig, Tiere aufzunehmen. Auch in Österreich gilt: Wer ein Fundtier nicht
                    meldet, kann gemäß dem Strafgesetzbuch wegen Fundunterschlagung geahndet werden.</p>
            )
        },
        {
            title: "Vorgehen bei Fundtieren in der Schweiz",
            content: (
                <div>
                    <p>Wenn Ihre Schildkröte in der Schweiz entlaufen ist, sollten Sie den Verlust sofort beim kantonalen Tierfundbüro melden. Eine Übersicht der Tierfundbüros finden Sie hier:</p>
                    <Button link="https://www.stmz.ch/de/index.php?lang=de&page=7&pageId=50"
                            text="Kantonale Meldestellen"/>
                </div>
            )
        },
    ];

    return (
        <div>
            <div className="intro-help">
                <div className="content-padding intro-help-container">
                    <h1 className="help-h1">Was tun im Notfall?</h1>
                    <p>
                        Egal ob Sie eine Schildkröte vermissen oder eine gefunden haben, auf dieser Seite finden Sie die
                        ersten Schritte, die Sie unternehmen können.

                    </p>
                    <p>
                        Wenn Sie eine Schildkröte vermissen, erfahren Sie
                        hier, wie Sie die Chancen erhöhen, Ihr Tier wiederzufinden. Falls Sie eine Schildkröte gefunden
                        haben, bieten wir Ihnen Hinweise, wie Sie richtig handeln, um das Tier sicher zu versorgen und
                        den Besitzer zu ermitteln.
                    </p>
                    {/* Link Section */}

                    <div className="anchor-section">
                        <Button link="#TierGefunden" text="→ Ich habe eine Schildkröte gefunden"/>
                        <Button link="#TierEntlaufen" text="→ Ich vermisse meine Schildkröte"/>
                    </div>
                </div>
                <MapSection/>
            </div>

            <div className="spacer-50"></div>
            <Divider/>

            <div className="content-padding" id="TierGefunden">
                <h2 className="bigger-heading">Schildkröte gefunden</h2>
                <p>Wenn Sie eine Schildkröte gefunden haben, finden Sie hier die länderspezifischen Schritte für
                    Deutschland, Österreich und die Schweiz, um das Tier sicher zu versorgen und den rechtmäßigen
                    Besitzer zu ermitteln.</p>
                <p>In der nächsten Sektion finden Sie Informationen, wie Sie gefundene Wasser- oder Landschildkröten
                    vorübergehend unterbringen können.</p>
                <Accordion items={accordionItems}/>
            </div>

            <Divider/>

            <div>
                <ContentSection
                    imageSrc={lsk}
                    heading="Notunterbringung Landschildkröten"
                    alt="Eine Box mit Erde, Stroh, Versteckmöglichkeit, und einer Wasserschale"
                    paragraphs={[
                        "Verwenden Sie eine große Kiste mit glatten, hohen Wänden und füllen Sie diese etwa 10 cm hoch mit Erde. Alternativ eignen sich große Gitterkäfige, deren unterer Bereich undurchsichtig gestaltet sein sollte. Achten Sie darauf, dass Schildkröten gute Kletterer sind.",
                        "Stellen Sie einen Strohhaufen oder eine Kartonschachtel als Versteck in eine Ecke. Platzieren Sie einen flachen Blumenuntersetzer mit handwarmem Wasser in der Kiste, sodass die Schildkröte sich darin baden kann.",
                        "Stellen Sie die Kiste tagsüber an einen schattigen Platz im Freien und sichern Sie sie mit einem Gitter. Holen Sie die Schildkröte nachts oder bei Temperaturen unter 20°C ins Haus, um sie vor Fressfeinden und Kälte zu schützen. Für den Transport eignet sich eine Styroporbox, ausgelegt mit Zeitungspapier und Heu.",
                        "Füttern Sie die Schildkröte ausschließlich mit Wildkräutern wie Löwenzahn, Wegerich, Brennnesseln, Disteln oder Klee. Im Notfall kann auch Romana-Salat gegeben werden. Obst und Gemüse sind ungeeignet."
                    ]}
                    buttons={[]}
                    imageRight={true}
                />
            </div>

            <div className="spacer-30"></div>

            <div>
                <ContentSection
                    imageSrc={wsk}
                    heading="Notunterbringung Wasserschildkröten"
                    alt="Ein Planschbecken mit einer Wasserschildkröte, mit einem Ast, Wasserpflanzen, und einer Brücke."
                    paragraphs={[
                        "Fassen Sie die Wasserschildkröte am hinteren Drittel an, um Bisse zu vermeiden. Achtung: Die Schildkröte könnte vor Angst Urin absetzen.",
                        "Als Notquartier eignen sich eine Mörtelwanne, große Waschwanne, Planschbecken oder Badewanne. Füllen Sie diese mit Wasser in Umgebungstemperatur, etwa so hoch wie der Panzer der Schildkröte, damit sie mühelos Luft holen kann. Ein Landteil, wie eine Weidenbrücke oder ein großer Stein, sollte vorhanden sein. Falls möglich, fügen Sie einen Luftsprudler hinzu und stellen eine kleine Lampe zum Sonnen bereit.",
                        "Füttern Sie die Schildkröte mit Löwenzahn oder ungewürztem, tiefgekühltem Garnelen- oder Muschelfleisch. Alternativ eignet sich auch ein Regenwurm. Wechseln Sie das Wasser so oft wie möglich.",
                        "Für den Transport zum Tierarzt oder einer Auffangstation setzen Sie die Schildkröte ohne Wasser in eine Styroporbox oder zur Not in einen Stoffbeutel, der dann in einem Karton platziert wird."
                        ]}
                    buttons={[]}
                    imageRight={false}
                />
            </div>

            <div className="spacer-50"></div>
            <Divider/>

            <div id="TierEntlaufen" className="content-padding">
                <h2 className={"bigger-heading"}>Schildkröte entlaufen</h2>
                <p>
                    Wenn Ihre Schildkröte entlaufen ist, gibt es einige wichtige Schritte, die Sie unternehmen sollten,
                    um die Chancen auf eine erfolgreiche Rückkehr zu erhöhen. Informieren Sie Ihre Nachbarn im Umkreis
                    von etwa 500 Metern und hängen Sie Flyer in der Umgebung sowie in Kitas, Schulen, Supermärkten und
                    Tierarztpraxen auf. </p>


                <p>Durchsuchen Sie Ihr Gehege, Ihren Garten und Teich täglich gründlich, da sich viele Schildkröten
                    gerne in der Erde oder unter Pflanzenwurzeln verstecken. Der Einsatz von Suchhunden (Pet-Trailer)
                    oder Drohnen mit Wärmebildkameras (z.B. von lokalen Rehkitz Rettungsdiensten) in den ersten Tagen
                    nach dem Verschwinden hat sich ebenfalls bewährt.</p>
                <p> Melden Sie den Verlust unbedingt auch beim Fundbüro, Tierheim und der Artenschutzbehörde. Und
                    vergessen Sie nicht, eine Suchmeldung in unserem Kontaktformular abzugeben, damit wir Ihre
                    Informationen weiter verbreiten können.</p>
                <Button link="/kontakt" text="→ zum Such- und Meldeformular"/>
            </div>
        </div>
    );
}

export default Hilfe;
