import headerimage from './assets/turtle1.webp';
import redPin from './assets/redPin.svg';
import greenPin from './assets/greenPin.svg';
import bluePin from './assets/bluePin.svg';
import purplePin from './assets/purplePin.svg';
import greyPin from './assets/greyPin.svg';
import yellowPin from './assets/yellowPin.svg';
import schildi from './assets/photo_2024-07-26_15-33-26.webp'
import { Helmet } from 'react-helmet';
import './App.css';
import Button from './components/Button';
import Footer from "./components/Footer.js";
import ContentSection from "./components/ContentSection";
import Header from "./components/Header";
import {useInView} from 'react-intersection-observer';
import React, {useState, useEffect, useMemo} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import Kontakt from "./pages/Kontakt";
import Hilfe from "./pages/Hilfe";
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';


const IntroContent = () => {
    const staticText = 'Schildkröte';
    const dynamicTexts = useMemo(() => [
        'entlaufen?',
        'gefunden?',
        'vermisst?'
    ], []);


    const [currentText, setCurrentText] = useState(dynamicTexts[0]);
    const [textIndex, setTextIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState('fade-in');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFadeClass('fade-out');
            setTimeout(() => {
                setTextIndex(prevIndex => (prevIndex + 1) % dynamicTexts.length);
                setFadeClass('fade-in');
            }, 1000); // Match this to the fade-out duration
        }, 4000); // Change text every 4 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [dynamicTexts.length]);

    useEffect(() => {
        setCurrentText(dynamicTexts[textIndex]);
    }, [textIndex, dynamicTexts]);

    return (
        <main className="main-content">
            <div className="intro content-padding">
                <h1>
                    {staticText} <span className={fadeClass}>{currentText}</span>
                </h1>
                <p className="subtitle-color-detail">
                    Europaweite Such- und Fundmeldungen von Wasser- und Landschildkröten
                </p>
                <div className={"spacer-30"}></div>
                <Button link="/kontakt" text="Meldung erstellen  →"/>
            </div>

            <img className="image padding-top" src={headerimage} alt="Schildkröte die auf Gras sitzt und nach links schaut"/>
        </main>
    );
}


const CountUp = ({endValue}) => {
    const [count, setCount] = useState(0);
    const duration = 1; // Duration in seconds

    useEffect(() => {
        if (endValue === 0) return;

        let start = 0;
        const end = endValue;
        const increment = end / (duration * 1000 / 100);
        const interval = setInterval(() => {
            start += increment;
            if (start >= end) {
                setCount(end);
                clearInterval(interval);
            } else {
                setCount(Math.floor(start));
            }
        }, 100);

        return () => clearInterval(interval);
    }, [endValue]);

    return <h2>{count}+</h2>;
};

const StatsSection = () => {
    const {ref, inView} = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <section className="stats-section" ref={ref}>
            <div className="stats-overlay">
                <div className="stat">
                    {inView && <CountUp endValue={10500}/>}
                    <p>Fundmeldungen</p>
                </div>
                <div className="stat">
                    {inView && <CountUp endValue={6800}/>}
                    <p>Schildkröten nach Hause gebracht</p>
                </div>
                <div className="stat">
                    {inView && <CountUp endValue={2000}/>}
                    <p>Mitglieder</p>
                </div>
            </div>
        </section>
    );
};

const MapSection = () => {
    return (
        <section className="map-section content-padding" id="GoogleMap">
            <h2>Karte der Such- und Fundtiere 2024</h2>
            <div className="iframe-container" >
                <iframe
                    loading="lazy"
                    title="Google Map"
                    src="https://www.google.com/maps/d/embed?mid=1SmiMY18ShXUnWTuYBcUFWuIaVKbKOwk&ehbc=2E312F"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{border: 0}}
                    allowFullScreen
                ></iframe>
            </div>
        </section>
    );
}

const MapLegend = () => {
    return (
        <div>
            <section className="map-legend">
                <div className="legend-item">
                    <img src={redPin} alt="Roter Pin" className="legend-icon"/>
                    <span className="legend-text">Vermisst</span>
                </div>
                <div className="legend-item">
                    <img src={greenPin} alt="Grüner Pin" className="legend-icon"/>
                    <span className="legend-text">Gefunden</span>
                </div>
                <div className="legend-item">
                    <img src={bluePin} alt="Blauer Pin" className="legend-icon"/>
                    <span className="legend-text">Happy End</span>
                </div>
                <div className="legend-item">
                    <img src={purplePin} alt="Lila Pin" className="legend-icon"/>
                    <span className="legend-text">Auffangstationen</span>
                </div>
                <div className="legend-item">
                    <img src={greyPin} alt="Grauer Pin" className="legend-icon"/>
                    <span className="legend-text">Sonstige Exoten</span>
                </div>
                <div className="legend-item">
                    <img src={yellowPin} alt="Gelber Pin" className="legend-icon"/>
                    <span className="legend-text">Gestohlen</span>
                </div>
            </section>
            <div className={"spacer-30"}></div>
        </div>
    );
}

const Divider = () => {
    return (
        <section className="divider-section">
        </section>
    );
}

const ArrowIcon = ({ isOpen }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`arrow-icon ${isOpen ? 'open' : ''}`}
    >
        <path d="M12 15L6 9H18L12 15Z" fill="currentColor" />
    </svg>
);


const FaqAccordion = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        {
            question: "Was kann ich tun, wenn ich eine Schildkröte vermisse?",
            answer: [
                "Wenn du eine Schildkröte vermisst, handle sofort, indem du Flyer mit einem Bild und den Details der Schildkröte erstellst und den Verlust der Artenschutzbehörde, umliegenden Tierheimen und Auffangstationen, der lokalen Tierrettung, und dem Fundbüro meldest. Erstelle eine Suchmeldung über unser Kontaktformular und poste auf Social-Media-Plattformen wie Facebook oder Instagram mit Hashtags wie #Schildkrötensuche, um die Reichweite zu erhöhen.",
                "Informiere außerdem Nachbarn im Umkreis von bis zu 500 m sowie lokale Apps oder Gruppen, da Schildkröten bis zu 3 km am Tag zurücklegen können."
            ],
            buttons: [
                { link: "/Kontakt", text: "Suchmeldung abgeben" }
            ]
        },
        {
            question: "Was sollte ich tun, wenn ich eine Schildkröte finde?",
            answer: [
                "Wenn du eine Schildkröte gefunden hast, hebe sie vorsichtig auf und bringe sie in einen sicheren Bereich, wie zum Beispiel in eine hohe, glatte Kiste, die zunächst drinnen gesichert und vor Haustieren geschützt sein sollte. Erstelle eine Fundmeldung über unser Kontaktformular, und suche nach Zeitungsanzeigen, Kleinanzeigen, Flyern oder Social-Media-Beiträgen in der Region.",
                "Darüber hinaus solltest du den Fund dem zuständigen Fundbüro (im Ordnungsamt), der Artenschutzbehörde und den umliegenden Tierheimen melden, und ein Tierarzt kann überprüfen, ob das Tier gechippt ist.",
                "Solltest du nicht wissen, um welche Art es sich handelt oder ob es sich um eine Wasser- oder Landschildkröte handelt, kontaktiere uns, damit unser Expertenteam die Art bestimmen und dir sagen kann, wie du die gefundene Schildkröte sicher unterbringen kannst."

            ],
            buttons: [
                { link: "/Kontakt", text: "Fundmeldung abgeben" },
                { link: "/hilfe", text: "zur Hilfe-Seite" }
            ]
        },
        {
            question: "Gibt es eine Plattform, um vermisste, entlaufene oder gefundene Schildkröten zu melden?",
            answer: [
                "Ja, unsere seit 8 Jahren mit Social Media verknüpfte Web-Plattform ermöglicht es, Fotos und Beschreibungen hochzuladen und Schildkröten auf unserer Map mit gefundenen und gesuchten Wasser- und Landschildkröten zuzuordnen.",
                "Wird eine Fund- oder Suchschildkröte in unserer Map aufgenommen, so erfolgt ein Experten-Abgleich, um die vermissten Schildköten schnell nach Hause zu bringen."
            ],
            buttons: [
                { link: "#GoogleMap", text: "zur Karte" }
            ]
        },
        {
            question: "Wie kann ich meine Schildkröte davor schützen, verloren zu gehen?",
            answer: [
                "Um einen sicheren Lebensraum für deine Schildkröte zu gewährleisten, solltest du sicherstellen, dass dein Teich und Gehege absolut ausbruchsicher sind. Dies lässt sich gut mit Volierengehegen erreichen, wobei der untere Bereich mit einem glatten, undurchsichtigen Material versehen sein sollte, um das Klettern zu verhindern. Schildkröten sind Ausbrecherkönige, daher ist es wichtig, auch die obere Seite der Gehege abzusichern.",
                "Außerdem solltest du deine Schildkröte ab einem Gewicht von 500 g bei einem Reptilientierarzt mikrochippen lassen und das Tier bei Findefix, Tasso oder ähnlichen Organisationen anmelden, um die Identifikation bei einem Fund zu erleichtern.",
                "Regelmäßige Kontrollen des Aufenthaltsorts deiner Schildkröte sind ebenfalls wichtig, insbesondere nach starkem Regen oder Stürmen."
            ]
        },
        {
            question: "Finderlohn für gefundene Schildkröten?",
            answer: [
                "Manche Schildkrötenbesitzer bieten kleine Belohnungen an, wenn ihre Schildkröte sicher zurückgebracht wird. Dies kann gelegentlich helfen, die Schildkröte schneller wieder nach Hause bringen."
            ]
        },
        {
            question: "Wie kann ich eine nicht beanspruchte Schildkröte adoptieren?",
            answer: [
                "Wenn eine Schildkröte nach längerer Zeit (in Deutschland in der Regel 6 Monate) nicht in der Schildkröten-Auffangstation oder dem Tierheim abgeholt wird, kann sie durch die Behörden eventuell zur Adoption freigegeben werden. Informiere dich bei deinem örtlichen Tierheim oder einer Schildkröten-Auffangstation über Adoptionsmöglichkeiten."
            ],
            buttons: [
                { link: "/hilfe", text: "Karte der Auffangstationen" }
            ]
        },
        {
            question: "Gibt es Aktionen oder Events rund um vermisste Schildkröten?",
            answer: [
                "Ja, das Team von schildkroetensuche.org ist auf zahlreichen Veranstaltungen rund um Schildkröten und den Tierschutz vertreten und bietet Beratung zum Umgang mit gefundenen und vermissten Schildkröten an. Diese Events tragen dazu bei, das Bewusstsein für vermisste Schildkröten zu schärfen und die Gemeinschaft zu vernetzen.",
                "Wenn du im Schildkröten- oder Tierschutz tätig bist und unsere ehrenamtliche Arbeit unterstützen möchtest, freuen wir uns über eine Kontaktaufnahme. Gerne stellen wir kostenloses Informationsmaterial zur Verfügung."
            ],
            buttons: [
                { link: "mailto:kontakt@schildkroetensuche.org", text: "E-Mail senden" }
            ]
        }
    ];


    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqData.map(faq => ({
            "@type": "Question",
            "name": faq.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer.join(' ') // Join paragraphs into a single string for schema
            }
        }))
    };

    return (
        <div className="content-padding faq-container">
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(faqSchema)}
                </script>
            </Helmet>
            <h2>FAQ – Häufig gestellte Fragen und Antworten</h2>
            <div className="faq-placeholder"></div>
            <div className="faq-width">
                {faqData.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div className="faq-question" onClick={() => handleToggle(index)}>
                            {faq.question}
                            <ArrowIcon isOpen={activeIndex === index}/>
                        </div>
                        <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                            {faq.answer.map((paragraph, paraIndex) => (
                                <p key={paraIndex} className="faq-answer-paragraph">{paragraph}</p> // Render each paragraph in a <div> element
                            ))}
                            {faq.buttons && faq.buttons.length > 0 && (
                                <div className="button-container">
                                    {faq.buttons.map((button, btnIndex) => (
                                        <Button key={btnIndex} link={button.link} text={button.text}/>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};



function Home() {


    return (
        <div className={"resp-container"}>
            <IntroContent/>
            <div className="spacer-150"/>
            <StatsSection/>
            <MapSection/>
            <MapLegend/>
            <Divider/>
            <FaqAccordion/>
            <Divider/>
            <ContentSection
                imageSrc={schildi}
                heading="Über uns"
                alt="Eine Schildköte von hinten"
                paragraphs={[
                    "Egal ob eine Schildkröte entlaufen, vermisst, oder gefunden wurde – bei uns sind Sie richtig. Wir sind mehr als ein nur ein Haufen Schildkrötenfreunde: Bei uns arbeiten 15 engagierte Ehrenamtliche in verschiedenen Bereichen (Google Maps, Moderation, VK, Instagram, Anzeigen und Website) eng zusammen, um entlaufene und gefundene Schildkröten aus dem deutschsprachigen Europa wieder nach Hause zu bringen. Hinzu kommt noch unser wertvolles Team von Artbestimmung-Experten. ",
                    "Im Hochsommer kommen gelegentlich 70 Fund- und Suchmeldungen an einem Tag herein. Gemeinsam stecken wir viel Freude und Leidenschaft in unser Projekt. Über 6000 erfolgreich nach Hause gebrachte Schildkröten sprechen für sich. Wir arbeiten eng mit Artenschutzbehörden, Veterinärämtern, Auffangstationen, Pflegestellen, Tierheimen, Reptilientierärzten  und engagierten Haltern zusammen, um Fundtiere schnell gut versorgt zu wissen."
                ]}
                buttons={[
                    {link: "https://bit.ly/Schildisuche ", text: "zur Facebook Gruppe"},
                    {link: "mailto:kontakt@schildkroetensuche.org", text: "E-Mail senden"}
                ]}
                imageRight={true}
            />
        </div>
    );
}

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

// ScrollToAnchor Component
const ScrollToAnchor = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [hash]);

    return null;
};

const CanonicalTag = () => {
    const location = useLocation();
    return (
        <Helmet>
            <link rel="canonical" href={`https://www.schildkroetensuche.org${location.pathname}`} />
        </Helmet>
    );
};

function App() {
    return (
        <Router>
            <CanonicalTag />
            <ScrollToTop/>
            <ScrollToAnchor/>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/kontakt" element={<Kontakt/>}/>
                <Route path="/hilfe" element={<Hilfe/>}/>
                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/datenschutz" element={<Datenschutz/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
}


export default App;
